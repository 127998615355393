import "./App.css";

function App() {
  return (
    <svg viewBox="0 0 100 100">
      <defs>
        <path
          id="l1"
          d="M 50 91q 20 -5 0 -9.111q -20 -5 0 -9.111q 20 -5 0 -9.111q -20 -5 0 -9.111q 20 -5 0 -9.111q -20 -5 0 -9.111q 20 -5 0 -9.111q -20 -5 0 -9.111q 20 -5 0 -9.111M 50 9q -20 5 0 9.111q 20 5 0 9.111q -20 5 0 9.111q 20 5 0 9.111q -20 5 0 9.111q 20 5 0 9.111q -20 5 0 9.111q 20 5 0 9.111q -20 5 0 9.111"
        ></path>
      </defs>
      <g>
        <g id="c0">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-0s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c0" transform="rotate(60 50 50)" />
        <use href="#c0" transform="rotate(120 50 50)" />
        <use href="#c0" transform="rotate(180 50 50)" />
        <use href="#c0" transform="rotate(240 50 50)" />
        <use href="#c0" transform="rotate(300 50 50)" />
        <g id="c6" transform="rotate(-6 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-0.06s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.06s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c6" transform="rotate(60 50 50)" />
        <use href="#c6" transform="rotate(120 50 50)" />
        <use href="#c6" transform="rotate(180 50 50)" />
        <use href="#c6" transform="rotate(240 50 50)" />
        <use href="#c6" transform="rotate(300 50 50)" />
        <g id="c12" transform="rotate(-12 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-0.12s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.12s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c12" transform="rotate(60 50 50)" />
        <use href="#c12" transform="rotate(120 50 50)" />
        <use href="#c12" transform="rotate(180 50 50)" />
        <use href="#c12" transform="rotate(240 50 50)" />
        <use href="#c12" transform="rotate(300 50 50)" />
        <g id="c18" transform="rotate(-18 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-0.18s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.18s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c18" transform="rotate(60 50 50)" />
        <use href="#c18" transform="rotate(120 50 50)" />
        <use href="#c18" transform="rotate(180 50 50)" />
        <use href="#c18" transform="rotate(240 50 50)" />
        <use href="#c18" transform="rotate(300 50 50)" />
        <g id="c24" transform="rotate(-24 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-0.24s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.24s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c24" transform="rotate(60 50 50)" />
        <use href="#c24" transform="rotate(120 50 50)" />
        <use href="#c24" transform="rotate(180 50 50)" />
        <use href="#c24" transform="rotate(240 50 50)" />
        <use href="#c24" transform="rotate(300 50 50)" />
        <g id="c30" transform="rotate(-30 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-0.3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c30" transform="rotate(60 50 50)" />
        <use href="#c30" transform="rotate(120 50 50)" />
        <use href="#c30" transform="rotate(180 50 50)" />
        <use href="#c30" transform="rotate(240 50 50)" />
        <use href="#c30" transform="rotate(300 50 50)" />
        <g id="c36" transform="rotate(-36 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-0.36s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.36s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c36" transform="rotate(60 50 50)" />
        <use href="#c36" transform="rotate(120 50 50)" />
        <use href="#c36" transform="rotate(180 50 50)" />
        <use href="#c36" transform="rotate(240 50 50)" />
        <use href="#c36" transform="rotate(300 50 50)" />
        <g id="c42" transform="rotate(-42 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-0.42s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.42s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c42" transform="rotate(60 50 50)" />
        <use href="#c42" transform="rotate(120 50 50)" />
        <use href="#c42" transform="rotate(180 50 50)" />
        <use href="#c42" transform="rotate(240 50 50)" />
        <use href="#c42" transform="rotate(300 50 50)" />
        <g id="c48" transform="rotate(-48 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-0.48s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.48s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c48" transform="rotate(60 50 50)" />
        <use href="#c48" transform="rotate(120 50 50)" />
        <use href="#c48" transform="rotate(180 50 50)" />
        <use href="#c48" transform="rotate(240 50 50)" />
        <use href="#c48" transform="rotate(300 50 50)" />
        <g id="c54" transform="rotate(-54 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-0.54s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.54s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c54" transform="rotate(60 50 50)" />
        <use href="#c54" transform="rotate(120 50 50)" />
        <use href="#c54" transform="rotate(180 50 50)" />
        <use href="#c54" transform="rotate(240 50 50)" />
        <use href="#c54" transform="rotate(300 50 50)" />
        <g id="c60" transform="rotate(-60 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-0.6s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.6s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c60" transform="rotate(60 50 50)" />
        <use href="#c60" transform="rotate(120 50 50)" />
        <use href="#c60" transform="rotate(180 50 50)" />
        <use href="#c60" transform="rotate(240 50 50)" />
        <use href="#c60" transform="rotate(300 50 50)" />
        <g id="c66" transform="rotate(-66 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-0.66s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.66s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c66" transform="rotate(60 50 50)" />
        <use href="#c66" transform="rotate(120 50 50)" />
        <use href="#c66" transform="rotate(180 50 50)" />
        <use href="#c66" transform="rotate(240 50 50)" />
        <use href="#c66" transform="rotate(300 50 50)" />
        <g id="c72" transform="rotate(-72 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-0.72s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.72s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c72" transform="rotate(60 50 50)" />
        <use href="#c72" transform="rotate(120 50 50)" />
        <use href="#c72" transform="rotate(180 50 50)" />
        <use href="#c72" transform="rotate(240 50 50)" />
        <use href="#c72" transform="rotate(300 50 50)" />
        <g id="c78" transform="rotate(-78 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-0.78s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.78s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c78" transform="rotate(60 50 50)" />
        <use href="#c78" transform="rotate(120 50 50)" />
        <use href="#c78" transform="rotate(180 50 50)" />
        <use href="#c78" transform="rotate(240 50 50)" />
        <use href="#c78" transform="rotate(300 50 50)" />
        <g id="c84" transform="rotate(-84 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-0.84s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.84s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c84" transform="rotate(60 50 50)" />
        <use href="#c84" transform="rotate(120 50 50)" />
        <use href="#c84" transform="rotate(180 50 50)" />
        <use href="#c84" transform="rotate(240 50 50)" />
        <use href="#c84" transform="rotate(300 50 50)" />
        <g id="c90" transform="rotate(-90 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-0.9s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.9s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c90" transform="rotate(60 50 50)" />
        <use href="#c90" transform="rotate(120 50 50)" />
        <use href="#c90" transform="rotate(180 50 50)" />
        <use href="#c90" transform="rotate(240 50 50)" />
        <use href="#c90" transform="rotate(300 50 50)" />
        <g id="c96" transform="rotate(-96 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-0.96s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-0.96s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c96" transform="rotate(60 50 50)" />
        <use href="#c96" transform="rotate(120 50 50)" />
        <use href="#c96" transform="rotate(180 50 50)" />
        <use href="#c96" transform="rotate(240 50 50)" />
        <use href="#c96" transform="rotate(300 50 50)" />
        <g id="c102" transform="rotate(-102 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-1.02s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.02s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c102" transform="rotate(60 50 50)" />
        <use href="#c102" transform="rotate(120 50 50)" />
        <use href="#c102" transform="rotate(180 50 50)" />
        <use href="#c102" transform="rotate(240 50 50)" />
        <use href="#c102" transform="rotate(300 50 50)" />
        <g id="c108" transform="rotate(-108 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-1.08s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.08s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c108" transform="rotate(60 50 50)" />
        <use href="#c108" transform="rotate(120 50 50)" />
        <use href="#c108" transform="rotate(180 50 50)" />
        <use href="#c108" transform="rotate(240 50 50)" />
        <use href="#c108" transform="rotate(300 50 50)" />
        <g id="c114" transform="rotate(-114 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-1.14s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.14s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c114" transform="rotate(60 50 50)" />
        <use href="#c114" transform="rotate(120 50 50)" />
        <use href="#c114" transform="rotate(180 50 50)" />
        <use href="#c114" transform="rotate(240 50 50)" />
        <use href="#c114" transform="rotate(300 50 50)" />
        <g id="c120" transform="rotate(-120 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-1.2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.2s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c120" transform="rotate(60 50 50)" />
        <use href="#c120" transform="rotate(120 50 50)" />
        <use href="#c120" transform="rotate(180 50 50)" />
        <use href="#c120" transform="rotate(240 50 50)" />
        <use href="#c120" transform="rotate(300 50 50)" />
        <g id="c126" transform="rotate(-126 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-1.26s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.26s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c126" transform="rotate(60 50 50)" />
        <use href="#c126" transform="rotate(120 50 50)" />
        <use href="#c126" transform="rotate(180 50 50)" />
        <use href="#c126" transform="rotate(240 50 50)" />
        <use href="#c126" transform="rotate(300 50 50)" />
        <g id="c132" transform="rotate(-132 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-1.32s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.32s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c132" transform="rotate(60 50 50)" />
        <use href="#c132" transform="rotate(120 50 50)" />
        <use href="#c132" transform="rotate(180 50 50)" />
        <use href="#c132" transform="rotate(240 50 50)" />
        <use href="#c132" transform="rotate(300 50 50)" />
        <g id="c138" transform="rotate(-138 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-1.38s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.38s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c138" transform="rotate(60 50 50)" />
        <use href="#c138" transform="rotate(120 50 50)" />
        <use href="#c138" transform="rotate(180 50 50)" />
        <use href="#c138" transform="rotate(240 50 50)" />
        <use href="#c138" transform="rotate(300 50 50)" />
        <g id="c144" transform="rotate(-144 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-1.44s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.44s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c144" transform="rotate(60 50 50)" />
        <use href="#c144" transform="rotate(120 50 50)" />
        <use href="#c144" transform="rotate(180 50 50)" />
        <use href="#c144" transform="rotate(240 50 50)" />
        <use href="#c144" transform="rotate(300 50 50)" />
        <g id="c150" transform="rotate(-150 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-1.5s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.5s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c150" transform="rotate(60 50 50)" />
        <use href="#c150" transform="rotate(120 50 50)" />
        <use href="#c150" transform="rotate(180 50 50)" />
        <use href="#c150" transform="rotate(240 50 50)" />
        <use href="#c150" transform="rotate(300 50 50)" />
        <g id="c156" transform="rotate(-156 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-1.56s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.56s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c156" transform="rotate(60 50 50)" />
        <use href="#c156" transform="rotate(120 50 50)" />
        <use href="#c156" transform="rotate(180 50 50)" />
        <use href="#c156" transform="rotate(240 50 50)" />
        <use href="#c156" transform="rotate(300 50 50)" />
        <g id="c162" transform="rotate(-162 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-1.62s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.62s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c162" transform="rotate(60 50 50)" />
        <use href="#c162" transform="rotate(120 50 50)" />
        <use href="#c162" transform="rotate(180 50 50)" />
        <use href="#c162" transform="rotate(240 50 50)" />
        <use href="#c162" transform="rotate(300 50 50)" />
        <g id="c168" transform="rotate(-168 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-1.68s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.68s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c168" transform="rotate(60 50 50)" />
        <use href="#c168" transform="rotate(120 50 50)" />
        <use href="#c168" transform="rotate(180 50 50)" />
        <use href="#c168" transform="rotate(240 50 50)" />
        <use href="#c168" transform="rotate(300 50 50)" />
        <g id="c174" transform="rotate(-174 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-1.74s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.74s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c174" transform="rotate(60 50 50)" />
        <use href="#c174" transform="rotate(120 50 50)" />
        <use href="#c174" transform="rotate(180 50 50)" />
        <use href="#c174" transform="rotate(240 50 50)" />
        <use href="#c174" transform="rotate(300 50 50)" />
        <g id="c180" transform="rotate(-180 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-1.8s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.8s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c180" transform="rotate(60 50 50)" />
        <use href="#c180" transform="rotate(120 50 50)" />
        <use href="#c180" transform="rotate(180 50 50)" />
        <use href="#c180" transform="rotate(240 50 50)" />
        <use href="#c180" transform="rotate(300 50 50)" />
        <g id="c186" transform="rotate(-186 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-1.86s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.86s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c186" transform="rotate(60 50 50)" />
        <use href="#c186" transform="rotate(120 50 50)" />
        <use href="#c186" transform="rotate(180 50 50)" />
        <use href="#c186" transform="rotate(240 50 50)" />
        <use href="#c186" transform="rotate(300 50 50)" />
        <g id="c192" transform="rotate(-192 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-1.92s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.92s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c192" transform="rotate(60 50 50)" />
        <use href="#c192" transform="rotate(120 50 50)" />
        <use href="#c192" transform="rotate(180 50 50)" />
        <use href="#c192" transform="rotate(240 50 50)" />
        <use href="#c192" transform="rotate(300 50 50)" />
        <g id="c198" transform="rotate(-198 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-1.98s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-1.98s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c198" transform="rotate(60 50 50)" />
        <use href="#c198" transform="rotate(120 50 50)" />
        <use href="#c198" transform="rotate(180 50 50)" />
        <use href="#c198" transform="rotate(240 50 50)" />
        <use href="#c198" transform="rotate(300 50 50)" />
        <g id="c204" transform="rotate(-204 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-2.04s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.04s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c204" transform="rotate(60 50 50)" />
        <use href="#c204" transform="rotate(120 50 50)" />
        <use href="#c204" transform="rotate(180 50 50)" />
        <use href="#c204" transform="rotate(240 50 50)" />
        <use href="#c204" transform="rotate(300 50 50)" />
        <g id="c210" transform="rotate(-210 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-2.1s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.1s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c210" transform="rotate(60 50 50)" />
        <use href="#c210" transform="rotate(120 50 50)" />
        <use href="#c210" transform="rotate(180 50 50)" />
        <use href="#c210" transform="rotate(240 50 50)" />
        <use href="#c210" transform="rotate(300 50 50)" />
        <g id="c216" transform="rotate(-216 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-2.16s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.16s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c216" transform="rotate(60 50 50)" />
        <use href="#c216" transform="rotate(120 50 50)" />
        <use href="#c216" transform="rotate(180 50 50)" />
        <use href="#c216" transform="rotate(240 50 50)" />
        <use href="#c216" transform="rotate(300 50 50)" />
        <g id="c222" transform="rotate(-222 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-2.22s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.22s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c222" transform="rotate(60 50 50)" />
        <use href="#c222" transform="rotate(120 50 50)" />
        <use href="#c222" transform="rotate(180 50 50)" />
        <use href="#c222" transform="rotate(240 50 50)" />
        <use href="#c222" transform="rotate(300 50 50)" />
        <g id="c228" transform="rotate(-228 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-2.28s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.28s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c228" transform="rotate(60 50 50)" />
        <use href="#c228" transform="rotate(120 50 50)" />
        <use href="#c228" transform="rotate(180 50 50)" />
        <use href="#c228" transform="rotate(240 50 50)" />
        <use href="#c228" transform="rotate(300 50 50)" />
        <g id="c234" transform="rotate(-234 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-2.34s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.34s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c234" transform="rotate(60 50 50)" />
        <use href="#c234" transform="rotate(120 50 50)" />
        <use href="#c234" transform="rotate(180 50 50)" />
        <use href="#c234" transform="rotate(240 50 50)" />
        <use href="#c234" transform="rotate(300 50 50)" />
        <g id="c240" transform="rotate(-240 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-2.4s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.4s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c240" transform="rotate(60 50 50)" />
        <use href="#c240" transform="rotate(120 50 50)" />
        <use href="#c240" transform="rotate(180 50 50)" />
        <use href="#c240" transform="rotate(240 50 50)" />
        <use href="#c240" transform="rotate(300 50 50)" />
        <g id="c246" transform="rotate(-246 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-2.46s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.46s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c246" transform="rotate(60 50 50)" />
        <use href="#c246" transform="rotate(120 50 50)" />
        <use href="#c246" transform="rotate(180 50 50)" />
        <use href="#c246" transform="rotate(240 50 50)" />
        <use href="#c246" transform="rotate(300 50 50)" />
        <g id="c252" transform="rotate(-252 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-2.52s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.52s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c252" transform="rotate(60 50 50)" />
        <use href="#c252" transform="rotate(120 50 50)" />
        <use href="#c252" transform="rotate(180 50 50)" />
        <use href="#c252" transform="rotate(240 50 50)" />
        <use href="#c252" transform="rotate(300 50 50)" />
        <g id="c258" transform="rotate(-258 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-2.58s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.58s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c258" transform="rotate(60 50 50)" />
        <use href="#c258" transform="rotate(120 50 50)" />
        <use href="#c258" transform="rotate(180 50 50)" />
        <use href="#c258" transform="rotate(240 50 50)" />
        <use href="#c258" transform="rotate(300 50 50)" />
        <g id="c264" transform="rotate(-264 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-2.64s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.64s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c264" transform="rotate(60 50 50)" />
        <use href="#c264" transform="rotate(120 50 50)" />
        <use href="#c264" transform="rotate(180 50 50)" />
        <use href="#c264" transform="rotate(240 50 50)" />
        <use href="#c264" transform="rotate(300 50 50)" />
        <g id="c270" transform="rotate(-270 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-2.7s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.7s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c270" transform="rotate(60 50 50)" />
        <use href="#c270" transform="rotate(120 50 50)" />
        <use href="#c270" transform="rotate(180 50 50)" />
        <use href="#c270" transform="rotate(240 50 50)" />
        <use href="#c270" transform="rotate(300 50 50)" />
        <g id="c276" transform="rotate(-276 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-2.76s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.76s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c276" transform="rotate(60 50 50)" />
        <use href="#c276" transform="rotate(120 50 50)" />
        <use href="#c276" transform="rotate(180 50 50)" />
        <use href="#c276" transform="rotate(240 50 50)" />
        <use href="#c276" transform="rotate(300 50 50)" />
        <g id="c282" transform="rotate(-282 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-2.82s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.82s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c282" transform="rotate(60 50 50)" />
        <use href="#c282" transform="rotate(120 50 50)" />
        <use href="#c282" transform="rotate(180 50 50)" />
        <use href="#c282" transform="rotate(240 50 50)" />
        <use href="#c282" transform="rotate(300 50 50)" />
        <g id="c288" transform="rotate(-288 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-2.88s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.88s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c288" transform="rotate(60 50 50)" />
        <use href="#c288" transform="rotate(120 50 50)" />
        <use href="#c288" transform="rotate(180 50 50)" />
        <use href="#c288" transform="rotate(240 50 50)" />
        <use href="#c288" transform="rotate(300 50 50)" />
        <g id="c294" transform="rotate(-294 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-2.94s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-2.94s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c294" transform="rotate(60 50 50)" />
        <use href="#c294" transform="rotate(120 50 50)" />
        <use href="#c294" transform="rotate(180 50 50)" />
        <use href="#c294" transform="rotate(240 50 50)" />
        <use href="#c294" transform="rotate(300 50 50)" />
        <g id="c300" transform="rotate(-300 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c300" transform="rotate(60 50 50)" />
        <use href="#c300" transform="rotate(120 50 50)" />
        <use href="#c300" transform="rotate(180 50 50)" />
        <use href="#c300" transform="rotate(240 50 50)" />
        <use href="#c300" transform="rotate(300 50 50)" />
        <g id="c306" transform="rotate(-306 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-3.06s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.06s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c306" transform="rotate(60 50 50)" />
        <use href="#c306" transform="rotate(120 50 50)" />
        <use href="#c306" transform="rotate(180 50 50)" />
        <use href="#c306" transform="rotate(240 50 50)" />
        <use href="#c306" transform="rotate(300 50 50)" />
        <g id="c312" transform="rotate(-312 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-3.12s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.12s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c312" transform="rotate(60 50 50)" />
        <use href="#c312" transform="rotate(120 50 50)" />
        <use href="#c312" transform="rotate(180 50 50)" />
        <use href="#c312" transform="rotate(240 50 50)" />
        <use href="#c312" transform="rotate(300 50 50)" />
        <g id="c318" transform="rotate(-318 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(124,126,135)">
            <animateMotion
              dur="25.33s"
              begin="-3.18s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.18s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c318" transform="rotate(60 50 50)" />
        <use href="#c318" transform="rotate(120 50 50)" />
        <use href="#c318" transform="rotate(180 50 50)" />
        <use href="#c318" transform="rotate(240 50 50)" />
        <use href="#c318" transform="rotate(300 50 50)" />
        <g id="c324" transform="rotate(-324 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(160,162,171)">
            <animateMotion
              dur="25.33s"
              begin="-3.24s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.24s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c324" transform="rotate(60 50 50)" />
        <use href="#c324" transform="rotate(120 50 50)" />
        <use href="#c324" transform="rotate(180 50 50)" />
        <use href="#c324" transform="rotate(240 50 50)" />
        <use href="#c324" transform="rotate(300 50 50)" />
        <g id="c330" transform="rotate(-330 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(196,198,207)">
            <animateMotion
              dur="25.33s"
              begin="-3.3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.3s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c330" transform="rotate(60 50 50)" />
        <use href="#c330" transform="rotate(120 50 50)" />
        <use href="#c330" transform="rotate(180 50 50)" />
        <use href="#c330" transform="rotate(240 50 50)" />
        <use href="#c330" transform="rotate(300 50 50)" />
        <g id="c336" transform="rotate(-336 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(232,234,243)">
            <animateMotion
              dur="25.33s"
              begin="-3.36s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.36s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c336" transform="rotate(60 50 50)" />
        <use href="#c336" transform="rotate(120 50 50)" />
        <use href="#c336" transform="rotate(180 50 50)" />
        <use href="#c336" transform="rotate(240 50 50)" />
        <use href="#c336" transform="rotate(300 50 50)" />
        <g id="c342" transform="rotate(-342 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(13,15,24)">
            <animateMotion
              dur="25.33s"
              begin="-3.42s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.42s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c342" transform="rotate(60 50 50)" />
        <use href="#c342" transform="rotate(120 50 50)" />
        <use href="#c342" transform="rotate(180 50 50)" />
        <use href="#c342" transform="rotate(240 50 50)" />
        <use href="#c342" transform="rotate(300 50 50)" />
        <g id="c348" transform="rotate(-348 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(52,54,63)">
            <animateMotion
              dur="25.33s"
              begin="-3.48s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.48s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c348" transform="rotate(60 50 50)" />
        <use href="#c348" transform="rotate(120 50 50)" />
        <use href="#c348" transform="rotate(180 50 50)" />
        <use href="#c348" transform="rotate(240 50 50)" />
        <use href="#c348" transform="rotate(300 50 50)" />
        <g id="c354" transform="rotate(-354 50 50)">
          <circle cx="0" cy="0" r="0" fill="rgb(88,90,99)">
            <animateMotion
              dur="25.33s"
              begin="-3.54s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            >
              <mpath href="#l1" />
            </animateMotion>
            <animate
              attributeName="r"
              values="2.25;0;2.25"
              dur="25.33s"
              begin="-3.54s"
              repeatCount="indefinite"
              keyTimes="0;0.25;1"
              keySplines="0.19 0.62 0.78 0.65; 0.19 0.62 0.78 0.65"
              calcMode="spline"
            />
          </circle>
        </g>
        <use href="#c354" transform="rotate(60 50 50)" />
        <use href="#c354" transform="rotate(120 50 50)" />
        <use href="#c354" transform="rotate(180 50 50)" />
        <use href="#c354" transform="rotate(240 50 50)" />
        <use href="#c354" transform="rotate(300 50 50)" />
      </g>
    </svg>
  );
}

export default App;
